@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Lufga';
    src: url('./assets/font/Lufga-Regular.woff') format('woff'),
        url('./assets/font/Lufga-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

html {
    font-family: 'Lufga', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.bg-gradient {
    background: linear-gradient(96.48deg, #5B4C74 0%, #B064CA 100%);
}

.bg-gradient1 {
    background: linear-gradient(90deg, #ACA1DF 0%, #D8BCD2 100%);
}